<template>
  <h1 class="text-2xl font-extrabold text-black mb-4">Projects</h1>
  <div class="mt-10 space-y-5">
    <div
      v-for="project in projects.slice().reverse()"
      :key="project.id"
    >
      <div>
        <a :href="project.link" target="_blank" class="text-lg text-black font-bold no-underline hover:underline">{{ project.name }}</a>
      </div>
      <p class="text-grey-darkest text-base leading-normal mt-1">
        {{ project.description }}
      </p>
      <div class="text-grey-darkest text-base leading-normal mt-2">
        <a :href="project.link" target="_blank" class="text-grey-darker hover:text-black text-sm no-underline hover:underline">Visit the website →</a>
      </div>
    </div>
  </div>
</template>

<script>
import projects from '../projects';

export default {
  name: 'Projects',

  data() {
    return {
      projects,
    };
  },
};
</script>
