export default [
  {
    id: 1,
    name: 'SpoofDNA',
    description: 'A fake DNA report generator. with this website you can generate fake DNA report of your choice and make fool of your friends and family',
    link: 'https://www.spoofdna.com',
  },
  {
    id: 2,
    name: 'Roomers',
    description: 'We are a marketplace for interior design connecting clients with interior designers. You can filter by location, budget, style, service, and rating to find the designer right for you. Browse, chat, and book your service all in one place. Our mission is to bring transparency and simplicity to the world of interior design.',
    link: 'https://roomers.us',
  },
];
